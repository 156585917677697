import React, { FC } from 'react'
import Hero from '../components/hero'
import Layout from '../components/layout'

const Home: FC = () => {
  return (
    <Layout>
      <Hero />
    </Layout>
  )
}

export default Home
export { Home }
