import React, { FC } from 'react'
import { Col, Container, Row } from 'reactstrap'
import PortraitPng from '../../assets/images/Portrait.jpeg'

const Hero: FC = () => {
  return (
    <section className="coming-soon">
      <img
        className="coming-soon--bg animate__animated animate__fadeIn"
        src={PortraitPng}
        alt="Alt Text!"
      />
      <div className="coming-soon--overlay" />
      {/* <Container className="h-100">
        <Row className="h-100 justify-content-center align-items-end align-items-md-center">
          <Col
            xs={12}
            md={{ offset: 8, size: 4 }}
            className="text-center  mb-5 mb-md-0 pb-5 pb-md-0"
          >
            <h1>
              Coming Soon <span />
              <span />
              <span />
            </h1>
          </Col>
        </Row>
      </Container> */}
    </section>
  )
}

export default Hero
export { Hero }
